<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>

        <!-- *********************** OVERVIEW *********************** -->
        <section id="overview">
          <div class="bsat__paragraph">{{ $t("overview.paragraph") }}</div>
          <ReadMore>
            <div class="bsat__paragraph">{{ $t("overview.paragraph2") }}</div>
            <div class="bsat__paragraph">{{ $t("overview.paragraph3") }}</div>
          </ReadMore>
        </section>

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS ********************************* -->
        <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task.title")
                }}</strong>
              </template>
            </i18n>

            <i18n
              path="implementationRecommendations.taskLeader.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.taskLeader.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethods"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </ReadMore>
        </section>

        <!-- ************************ RECOMMENDED RESOURCES ********************************* -->
        <section id="recommendedResources">
          <div class="bsat__subtitle mb-4">
            {{ $t("recommendedResources.title") }}
          </div>

          <div class="bsat__paragraph mt-n3 mb-6">
            {{ $t("recommendedResources.paragraph") }}
          </div>

          <ul class="ml-0 ml-sm-4 bsat__ul">
            <li>
              <i18n
                path="recommendedResources.list1.txt"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a @click="goToTab(1)">{{
                    $t("recommendedResources.list1.link")
                  }}</a>
                </template>
              </i18n>
            </li>

            <li>
              <i18n
                path="recommendedResources.list2.txt"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a @click="goToTab(2)">{{
                    $t("recommendedResources.list2.link")
                  }}</a>
                </template>
              </i18n>
            </li>

            <li>
              <i18n
                path="recommendedResources.list3.txt"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a @click="goToTab(2)">{{
                    $t("recommendedResources.list3.link")
                  }}</a>
                </template>
              </i18n>
            </li>

            <li>
              <i18n
                path="recommendedResources.list4.txt"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <strong>{{ $t("recommendedResources.list4.link") }}</strong>
                </template>
              </i18n>

              <router-link :to="$t('recommendedResources.list4.to')">
                <v-btn
                  outlined
                  width="150"
                  height="26"
                  class="bsat__btn"
                  color="dark"
                  >{{ $t("visit") }}</v-btn
                >
              </router-link>
              <!-- <a
                :href="require('@/assets/files/financial_tools.docx').default"
                target="_blank"
                download
              >
                <v-btn
                  class="bsat__btn"
                  color="dark"
                  dark
                  depressed
                  width="150"
                  height="26"
                  >{{ $t("download") }}</v-btn
                >
              </a> -->
            </li>

            <li>
              <i18n
                path="recommendedResources.list5.txt"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <strong>{{ $t("recommendedResources.list5.link") }}</strong>
                </template>
              </i18n>

              <router-link :to="$t('recommendedResources.list5.to')">
                <v-btn
                  outlined
                  width="150"
                  height="26"
                  class="bsat__btn"
                  color="dark"
                  >{{ $t("visit") }}</v-btn
                >
              </router-link>
              <!-- <a
                :href="require('@/assets/files/financial_tools.docx').default"
                target="_blank"
                download
              >
                <v-btn
                  class="bsat__btn"
                  color="dark"
                  dark
                  depressed
                  width="150"
                  height="26"
                  >{{ $t("download") }}</v-btn
                >
              </a> -->
            </li>

            <li>
              <i18n
                path="recommendedResources.list6.txt"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a @click="goToTab(2)">{{
                    $t("recommendedResources.list6.link")
                  }}</a>
                </template>
              </i18n>
            </li>

            <!--<li>
              <i18n
                path="recommendedResources.list7.txt"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <strong>{{ $t("recommendedResources.list7.link") }}</strong>
                </template>
              </i18n>

               <a :href="file" target="_blank" download>
                <v-btn
                  depressed
                  width="150"
                  height="26"
                  class="bsat__btn"
                  dark
                  color="dark"
                  >{{ $t("download") }}</v-btn
                >
              </a>
            </li>-->
          </ul>
        </section>

        <i18n
          class="mt-8"
          tag="div"
          path="downloadAll.text"
          style="font-family: Montserrat"
        >
          <template #link>
            <a class="dark-text mt-8" :href="zip" target="_blank" download>
              {{ $t("downloadAll.link") }}
            </a>
          </template>
        </i18n>
      </div>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "implementationRecommendations": {
      "proposedMethods": {
        "title": "Proposed methods",
        "unorderedList": [
          "Appointing the people that will conform the strategy development working group.",
          "Meetings of the strategy development working group to reach the decisions of the strategy development plan"
        ]
      },
      "task": {
        "text": "{title}: Define project management  plan to define the bioeconomy strategy",
        "title": "Task"
      },
      "taskLeader": {
        "text": "{title}: Initially Interministerial/Interdepartmental group, while the strategy development working group is organized.",
        "title": "Task leader"
      },
      "title": "Implementation Recommendations"
    },
    "overview": {
      "paragraph": "In close coordination between regional policy makers, the advisory group and other experts from RBH, the plan to develop the regional strategy will be defined. This entails practicalities such as designating a group in charge of drafting the strategy document (usually technical experts within ministries). It would be ac-companied by a supporting group for advisory and decision making during the process (usually policy makers from Interministerial group).",
      "paragraph2": "Also, other key elements will be defined, such as  the participatory process, groups of interest to engage, thematic of priority to be discussed, methodologies of engagement such as workshops or focus groups and specific type of information to be collected from interaction with engaged stakeholders, among others.",
      "paragraph3": "During this step, any required experts to be further included in the advisory group, from industry, regional policy makers, R&D+i and civil society will be identified. "
    },
    "recommendedResources": {
      "title": "Recommended resources",
      "paragraph": "General documents to frame the exercise will be required. The following elements are acting as supportive tools and stages  to use the “same terminology” along the process. Also, content-wise, it is highly recommended to set up the playground from the same basic reference documents.",
      "list1": {
        "link": "(Phase 1)",
        "txt": "Results of regional SWOT analysis {link} to take into account while deciding on the plan "
      },
      "list2": {
        "link": "(Phase 2)",
        "txt": "Engagement process of the RBH and communication protocols {link},to support the design of the participatory approach. "
      },
      "list3": {
        "link": "Phase 2",
        "txt": "Co-created vision {link}"
      },
      "list4": {
        "link": "Repository of Supporting documents and tools for regional policies in the field of bioeconomy.",
        "to": "/repository-of-regional-policies-to-support-bio-based-models",
        "txt": "{link} A long and thorough list of documents are available to get an the EU Policy overview in the area of bioeconomy"
      },
      "list5": {
        "link": "Repository of Supporting documents and tools for the implementation of EU financial instruments in the area of bioeconomy",
        "to": "/repository-of-financing-instruments-and-tools",
        "txt": "{link}"
      },
      "list6": {
        "link": "(Phase 2)",
        "txt": "RBH´s Guideline (stakeholder involvement section) {link}"
      },
      "list7": {
        "link": "",
        "to": "#",
        "txt": "Guideline for strategy and roadmap development for more detailed information about the execution of Phase 3 and Phase 4."
      }
    },
    "title": "Step 1: Define the strategy development plan between RBH and regional policy makers",
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
  }
}
</i18n>

<script>
import ReadMore from "@/components/ReadMore";
import { EventBus } from "@/utils/EventBus";
export default {
  name: "Phase3Step1",
  components: {
    ReadMore
  },
  data() {
    return {
      // TODO: 1.3.1_1
      file: "",
      zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
    };
  },
  computed: {
    proposedMethods() {
      return Object.values(
        this.$t("implementationRecommendations.proposedMethods.unorderedList")
      );
    },
    resourcesUnorderedList() {
      return Object.values(this.$t("recommendedResources.unorderedList"));
    }
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";

.list {
  $font-family: $font-family;
  // font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;

  &-link {
    color: $light_green !important;
  }
}
</style>
